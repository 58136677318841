<template>
    <div class="content-box">

        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        v-if="'id' in tool && !isOwner()"
                        class="row"
                        :label="$t('forms.status')"
                        label-for="h-status"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-status"
                            v-model="statusSelect"
                            :options="statuses"
                        />
                    </b-form-group>

                    <b-form-group
                        v-if="'id' in tool && !isOwner()"
                        class="row"
                        :label="$t('forms.responsiblePerson')"
                        label-for="responsiblePerson"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="responsiblePerson"
                            v-model="employeeSelect"
                            :options="employees"
                        />
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.stationSelect.$errors.length }"
                        :label="$t('forms.station')"
                        label-for="h-station"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-station"
                            v-model="stationSelect"
                            :options="stations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stationSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.tool.description.$errors.length }"
                        :label="$t('forms.description')"
                        label-for="description"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea v-model="tool.description" id="description"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.tool.description.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.tool.partNumber.$errors.length }"
                        :label="$t('forms.partNumberLong')"
                        label-for="partNumber"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="tool.partNumber" id="partNumber" type="text"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.tool.partNumber.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.priority')"
                        label-for="h-priority"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-priority"
                            v-model="prioritySelect"
                            :options="priorities"
                        />
                    </b-form-group>

                    <b-form-group
                        v-if="prioritySelect.id === 'HIL' || prioritySelect.id === 'DEFERRED_ITEM'"
                        class="row"
                        :label="$t('forms.deadline')"
                        label-for="deadline"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <datepicker
                            id="deadline"
                            v-model="tool.deadline"
                            :enableTimePicker="false"
                            class="form-input"
                            format="dd.MM.yyyy"
                        />
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.supplier')"
                        label-for="supplier"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="tool.supplier" id="supplier" type="text"/>

                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.remark')"
                        label-for="remark"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea v-model="tool.remark" id="supplier"/>

                    </b-form-group>

                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import {getUserData} from "@/auth/utils";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        toolObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList(["tool_request_priority", "tool_request_status"], this.priorities, this.statuses);
        this.getUserList({params: {'role': "ROLE_EMPLOYEE"}}, this.employees)
        this.getResourceList(this.$Stations, this.stations)
        if ("id" in this.tool) {
            this.stationSelect = {
                label: this.tool.station.name,
                id: this.tool.station['@id'],
            };
            this.prioritySelect = {
                label: this.$helper.getEnumTranslation('tool_request_priority', this.tool.priority, this.$i18n.locale),
                id: this.tool.priority
            };
            this.statusSelect = {
                label: this.$helper.getEnumTranslation('tool_request_status', this.tool.status, this.$i18n.locale),
                id: this.tool.status
            };
            if (this.tool.responsiblePerson) {
                this.employeeSelect = {
                    label: this.tool.responsiblePerson.givenName + this.tool.responsiblePerson.familyName,
                    id: this.tool.responsiblePerson['@id']
                }
            }
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            stationSelect: {required},
            tool: {
                description: {required},
                partNumber: {required}
            },
        };
    },
    data() {
        return {
            tool: this.toolObject,
            stations: [],
            stationSelect: {},
            priorities: [],
            prioritySelect: {},
            statuses: [],
            statusSelect: {},
            employees: [],
            employeeSelect: {}
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.tool.station = this.stationSelect.id;
                this.tool.priority = this.prioritySelect.id;
                if (this.action === "add") {
                    this.tool.status = "ACTIVE"
                    this.create(
                        this.$ToolRequests,
                        this.tool,
                        this.$t("messages.toolRequestCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.tool.status = this.statusSelect.id;
                    this.tool.responsiblePerson = this.employeeSelect.id;
                    this.$emit("clearAction");
                    this.update(
                        this.$ToolRequests,
                        this.tool.id,
                        this.tool,
                        this.$t("messages.toolRequestUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        isOwner() {
            return "employee" in this.tool &&
                getUserData().id === this.tool.employee.id;
        },
        success() {
            this.$router.push(`/tool-requests`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

